body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
}

table th:nth-child(4) {
  width: 15%;
}

table th:last-child, table td:last-child {
  width: 20%;
  text-align: center;
}

table td {
  border-bottom: thin solid #6F8091;
	text-align: left;
}

#main-table thead {
  color: white;
  background-color: #456789;
}

.text-header {
  text-align: left;
}

.num-header {
  text-align: center;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
}


.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 4px;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #1b4060;
    background-color: #eeeeee;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: #2c689c;
    border-color: #2c689c;
    cursor: default;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

table {
  font-family: '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"';
}

.pagination-text {
  margin-top: 1rem;
}

.table-title {
  margin-bottom: .5rem;
}

.page-container {
  margin-left: 1rem;
}

@media(max-width: 760px) {
  .page-container {
    margin-right: 1rem !important;
  }
}

.search-bar {
  margin: .25rem 0rem;
}

.sort-div {
  display: inline-block;
  margin-right: .5rem;
  margin-bottom: 1rem;
}

#individual-header {
  font-size: 2.25rem;
  margin-bottom: .3rem;
}

#badge-number {
  display: inline-block; 
  font-weight: normal;
  margin-bottom: .3rem;
}

#officer-description {
  margin-top: 0rem;
  margin-bottom: .3rem;
  font-weight: normal;
  font-style: italic;
}

.individual-page-attributes {
  list-style-type: none;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  font-size: 1rem;
}

.individual-page-stats {
  list-style-type: none;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0;
  font-size: 1rem;
}

.individual-page-stats#command-unit {
  margin-top: 4rem;
}

#stats-span {
  font-weight: bold;
  font-size: 1.3rem;
  font-style: italic;
  margin-right: .2rem;
}

.text-parent {
  width: 500px;
}

@media(max-width: 915px) {
  .map-parent {
    order: 1;
    margin: 0 auto !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
  .text-parent {
    order: 2;
    align-self: center;
  }
}

.barChart {
  height: auto;
  width: 100%;
  max-width: 600px;
}

.lineChart {
  height: auto;
  width: 100%;
  max-width: 600px;
}

#associated-precinct {
  margin-top: 0rem;
  margin-bottom: .3rem;
  font-weight: normal;
  font-style: italic;
}

@media only screen and (max-width: 400) {
  .precinct-map {
    max-width: 350px;
  }
}

